/**
 * @file 404 样式
 * @author FengGuang(fengguang01@baidu.com)
 */
@import '../../style/global';
@import '../../components/header/style';
@import '../../components/footer/style';

.paddle-404-page {
    padding-top: @header-height;

    &-container {
        margin: 0 auto;
        max-width: 1200px;
        padding-top: 100px;
    }

    &-context {
        text-align: center;
    }

    &-img-wrap {
        text-align: center;
    }

    &-img {
        background-image: url(images/404.png);
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
        display: inline-block;
        height: 150px;
        width: 150px;
    }

    &-title {
        font-size: 36px;
        font-weight: 600;
        color: #808080;
        line-height: 36px;
        margin-top: 27px;
    }

    &-subtitle {
        font-size: 16px;
        font-weight: 400;
        color: #999;
        text-align: center;
        line-height: 14px;
        margin-top: 17px;
    }

    &-btn-group {
        margin-top: 40px;
    }

    &-btn {
        border-radius: 999999px;
        width: 120px;
    }
}

@primary-color: #2932e1;@link-color: #2932e1;@fill-body: ~"";