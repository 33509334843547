/**
 * @file 404 样式
 * @author FengGuang(fengguang01@baidu.com)
 */
/**
 * @file 公用样式
 * @author FengGuang(fengguang01@baidu.com)
 */
html,
body {
  min-width: 300px;
}
.paddle-context-h1 {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0;
  margin-top: 30px;
}
.paddle-context-h3 {
  color: #000;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 22px;
  margin-top: 10px;
}
.paddle-context-h3 a {
  color: #2932e1;
  margin-left: 8px;
}
.clear-both:after {
  content: "";
  clear: both;
  display: block;
}
.vertical-middle {
  text-align: center;
}
.vertical-middle > * {
  display: inline-block;
  vertical-align: middle;
}
.vertical-middle:after {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
/**
 * @file 顶部导航栏样式
 * @author FengGuang(fengguang01@baidu.com)
 */
/**
* @file 顶部导航栏搜索框样式
* @author FengGuang(fengguang01@baidu.com)
*/
.paddle-header-search {
  display: inline-block;
  width: 100%;
}
.paddle-header-search .ant-select-selection__clear {
  display: none;
  right: 4px;
}
.paddle-header-search-wrap {
  text-align: right;
}
.paddle-header-search .ant-select-selection {
  background-color: transparent;
}
.paddle-header-search.ant-select-auto-complete.ant-select .ant-select-selection__rendered {
  line-height: 22px;
}
.paddle-header-search.ant-select-auto-complete.ant-select .ant-input {
  height: 22px;
}
.paddle-header-search .ant-input {
  border-radius: 999999px;
  border-color: rgba(255, 255, 255, 0.5);
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  height: 22px;
  margin-left: 4px;
  padding-right: 0;
}
.paddle-header-search .ant-input:not(:focus) {
  background-color: transparent;
}
.paddle-header-search .ant-input:focus {
  background-color: #1527c2;
}
.paddle-header-search .ant-input:hover,
.paddle-header-search .ant-input:focus {
  border-color: rgba(255, 255, 255, 0.5);
}
.paddle-header-search .ant-input::-moz-placeholder {
  color: #6d78d3;
  font-size: 12px;
}
.paddle-header-search .ant-input:-ms-input-placeholder {
  color: #6d78d3;
  font-size: 12px;
}
.paddle-header-search .ant-input::-webkit-input-placeholder {
  color: #6d78d3;
  font-size: 12px;
}
.paddle-header-search .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
  border-color: rgba(255, 255, 255, 0.5);
}
.paddle-header-search .ant-select-selection__clear {
  background: transparent;
}
.paddle-header-search .anticon {
  color: #fff;
}
.paddle-header-search .anticon.ant-select-clear-icon {
  background: #fff;
  color: #5a68d4;
  border-radius: 999999px;
}
.paddle-header-search-input .ant-input-prefix {
  background-color: transparent;
  height: auto;
}
.paddle-header-search-input-icon {
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(../../components/header/images/search.png);
  display: inline-block;
  height: 12px;
  width: 12px;
}
.paddle-header-search-drop {
  width: 300px;
}
.paddle-header-search-drop .ant-select-dropdown-menu {
  max-height: none;
}
.paddle-header-search-drop-item-divider {
  margin: 5px 20px 2px 20px;
  min-width: unset;
  width: auto;
}
.paddle-header-search-drop-item,
.paddle-header-search-drop-item-more {
  line-height: 40px;
  padding: 0 20px;
}
.paddle-header-search-drop-item-more {
  font-weight: 400;
  cursor: pointer;
}
.paddle-header-search-drop-item-type {
  float: right;
}
.paddle-header {
  height: 50px;
  margin: 0 auto;
  overflow: hidden;
  max-width: 1200px;
  padding: 14px 0;
}
.paddle-header-wrap {
  left: 0;
  min-width: 300px;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
}
.paddle-header-background {
  background-color: #1527c2;
  bottom: 0;
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}
.paddle-header a {
  text-decoration: none;
}
.paddle-header-logo-item {
  display: inline-block;
  height: 50px;
  overflow: hidden;
}
.paddle-header-logo-wrap {
  display: inline-block;
  height: 22px;
  margin: 0 19px;
  overflow: hidden;
}
.paddle-header-logo {
  height: 22px;
  width: 66px;
}
.paddle-header .paddle-header-menu-icon {
  background-image: url(../../components/header/images/menu.png);
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  height: 18px;
  width: 20px;
  margin: 0 20px 0 18px;
  vertical-align: middle;
  z-index: 1;
}
.paddle-header .ant-menu {
  background: transparent;
  color: #fff;
  font-weight: 400;
}
.paddle-header .ant-menu-item-group {
  padding: 14px 0;
}
.paddle-header .ant-menu-item-group-title {
  line-height: 40px;
  padding: 0 20px;
}
.paddle-header .ant-menu > .ant-menu-item-divider {
  margin: 0 20px;
}
.paddle-header .ant-menu > .ant-menu-item,
.paddle-header .ant-menu > .ant-menu-submenu {
  margin: 0 15px;
  padding: 0;
}
.paddle-header .ant-menu > .ant-menu-submenu > .ant-menu-submenu-title {
  padding: 0;
}
.paddle-header .ant-menu-item:hover,
.paddle-header .ant-menu-item-active,
.paddle-header .ant-menu:not(.ant-menu-inline) .paddle-header .ant-menu-submenu-open,
.paddle-header .ant-menu-submenu-active,
.paddle-header .ant-menu-submenu-title:hover {
  color: #fff;
}
.paddle-header .ant-menu-horizontal {
  border-bottom: none;
  line-height: 28px;
}
.paddle-header .ant-menu-horizontal > .ant-menu-item,
.paddle-header .ant-menu-horizontal > .ant-menu-submenu {
  border-bottom: 3px solid transparent;
}
.paddle-header .ant-menu-horizontal > .ant-menu-item:hover,
.paddle-header .ant-menu-horizontal > .ant-menu-submenu:hover,
.paddle-header .ant-menu-horizontal > .ant-menu-item-active,
.paddle-header .ant-menu-horizontal > .ant-menu-submenu-active,
.paddle-header .ant-menu-horizontal > .ant-menu-item-open,
.paddle-header .ant-menu-horizontal > .ant-menu-submenu-open,
.paddle-header .ant-menu-horizontal > .ant-menu-item-selected,
.paddle-header .ant-menu-horizontal > .ant-menu-submenu-selected {
  color: #fff;
  border-bottom: 3px solid rgba(255, 255, 255, 0.3);
  font-weight: 500;
}
.paddle-header .ant-menu-horizontal > .ant-menu-item > a {
  color: #fff;
}
.paddle-header .ant-menu-horizontal > .ant-menu-item > a:hover {
  color: #fff;
}
.paddle-header .ant-menu-horizontal > .ant-menu-item-selected > a {
  color: #fff;
}
.paddle-header .ant-menu-item-group-list .ant-menu-item,
.paddle-header .ant-menu-item-group-list .ant-menu-submenu-title {
  padding: 0 20px;
  margin: 0;
}
.paddle-header-right {
  float: right;
}
.paddle-header-search-wrap {
  display: inline-block;
  width: 156px;
  vertical-align: top;
}
.paddle-header-links {
  color: #fff;
  display: inline-block;
  line-height: 50px;
  margin-left: 10px;
}
.paddle-header-links-item {
  display: inline-block;
  padding: 0 10px;
}
.paddle-header-links-link {
  background-color: transparent;
  border: none;
  color: #fff;
  cursor: pointer;
  display: inline;
  margin: 0;
  padding: 0;
  text-decoration: none;
}
.paddle-header-links-link:hover {
  color: #fff;
}
.paddle-header-drawer .ant-drawer-body {
  padding: 20px 0 20px 20px;
}
.paddle-header-drawer .ant-drawer-content-wrapper {
  width: 244px;
}
.paddle-header-drawer-close {
  position: absolute;
  z-index: 10;
  display: block;
  width: 56px;
  height: 56px;
  padding: 0;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 700;
  font-size: 16px;
  font-style: normal;
  line-height: 56px;
  text-align: center;
  text-transform: none;
  text-decoration: none;
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  text-rendering: auto;
  top: 0;
  right: 0;
}
.paddle-header-drawer-close-icon {
  background-image: url(../../components/header/images/close.png);
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  height: 14px;
  width: 14px;
}
.paddle-header-drawer-logo-wrap {
  margin-top: 40px;
  width: 80px;
}
.paddle-header-drawer-logo {
  width: 100%;
}
.paddle-header-drawer-divider {
  border-top: 1px solid #000;
  margin-top: 10px;
}
.paddle-header-drawer-menu {
  border-right: none;
  font-size: 14px;
  margin-right: -20px;
  margin-top: 10px;
}
.paddle-header-drawer-menu .ant-menu-item-divider {
  display: none;
}
.paddle-header-drawer-menu .ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
  padding-left: 0;
}
.paddle-header-drawer-menu .ant-menu-item,
.paddle-header-drawer-menu .ant-menu-submenu-title,
.paddle-header-drawer-menu.ant-menu-inline .ant-menu-item,
.paddle-header-drawer-menu.ant-menu-inline .ant-menu-submenu-title {
  margin: 0;
}
.paddle-header-drawer-menu.ant-menu .ant-menu-item-selected {
  background-color: transparent;
}
.paddle-header-drawer-menu.ant-menu-inline .ant-menu-item::after {
  content: none;
}
.paddle-header-drawer-menu .ant-menu.ant-menu-sub.ant-menu-inline > .ant-menu-item {
  margin-left: 14px;
}
.paddle-header-drawer-menu .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item {
  margin-left: 14px;
}
.paddle-header-drawer-menu .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item:not(.ant-menu-item-selected),
.paddle-header-drawer-menu .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item:not(.ant-menu-item-selected) > a {
  color: #000;
}
.paddle-header-drawer-menu > .ant-menu-item,
.paddle-header-drawer-menu > .ant-menu-item > a,
.paddle-header-drawer-menu > .ant-menu-submenu > .ant-menu-submenu-title {
  color: #000;
  font-size: 16px;
  font-weight: 600;
}
.paddle-header-drawer-menu .ant-menu-item:hover,
.paddle-header-drawer-menu .ant-menu-item-selected,
.paddle-header-drawer-menu .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
.paddle-header-drawer-menu .ant-menu-submenu-selected,
.paddle-header-drawer-menu .ant-menu-submenu-title:hover {
  color: #2932e1;
}
/**
 * @file 页脚样式
 * @author FengGuang(fengguang01@baidu.com)
 */
.paddle-footer {
  background: #f5f5f5;
  color: #000;
  display: block;
  margin-top: 60px;
  min-height: 403px;
}
.paddle-footer-container {
  max-width: 768px;
  min-height: 353px;
  margin: 0 auto;
  overflow: hidden;
  padding: 20px;
}
.paddle-footer-group {
  margin-top: 20px;
  vertical-align: top;
  overflow: hidden;
}
.paddle-footer-group-title {
  color: #000;
  font-size: 14px;
  font-weight: 500;
}
.paddle-footer-item-list {
  font-size: 13px;
  font-weight: 400;
  line-height: 30px;
  margin-top: 5px;
  margin: 0 -15px;
}
.paddle-footer-item {
  color: #7b7b7b;
  display: inline-block;
  font-weight: 400;
  font-size: 14px;
  line-height: 36px;
  margin: 0 15px;
}
.paddle-footer-item a {
  color: #7b7b7b;
}
.paddle-footer-qr-wrap {
  border-top: 1px solid #dcdcdc;
  margin: 40px 0 -20px 0;
  padding: 26px 0 6px 0;
  text-align: center;
}
.paddle-footer-qr {
  background-size: contain;
  background-repeat: no-repeat;
  height: 100px;
  margin: 0 auto;
  overflow: hidden;
  width: 100px;
}
.paddle-footer-qr-img {
  opacity: 0;
  width: 100%;
}
.paddle-footer-qr-item {
  margin-bottom: 20px;
}
.paddle-footer-qr-title {
  color: #7e7e7e;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 10px;
  text-align: center;
}
.paddle-footer-qr-subtitle {
  font-size: 20px;
  transform: scale(0.5);
}
.paddle-footer-bottom {
  background: #eaeaea;
  color: #7b7b7b;
  font-size: 14px;
  height: 50px;
  line-height: 50px;
  text-align: center;
}
.paddle-404-page {
  padding-top: 50px;
}
.paddle-404-page-container {
  margin: 0 auto;
  max-width: 1200px;
  padding-top: 100px;
}
.paddle-404-page-context {
  text-align: center;
}
.paddle-404-page-img-wrap {
  text-align: center;
}
.paddle-404-page-img {
  background-image: url(images/404.png);
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  height: 150px;
  width: 150px;
}
.paddle-404-page-title {
  font-size: 36px;
  font-weight: 600;
  color: #808080;
  line-height: 36px;
  margin-top: 27px;
}
.paddle-404-page-subtitle {
  font-size: 16px;
  font-weight: 400;
  color: #999;
  text-align: center;
  line-height: 14px;
  margin-top: 17px;
}
.paddle-404-page-btn-group {
  margin-top: 40px;
}
.paddle-404-page-btn {
  border-radius: 999999px;
  width: 120px;
}
